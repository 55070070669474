{
  "locale.en": "English",
  "locale.de": "German",

  "common.add": "Add",
  "common.apply": "Apply",
  "common.cancel": "Cancel",
  "common.close": "Close",
  "common.copied": "Copied!",
  "common.copy": "Copy",
  "common.download": "Download",
  "common.loading": "Loading…",
  "common.manage": "Manage",
  "common.next": "Next",
  "common.optional": "optional",
  "common.pagination": "Pagination",
  "common.previous": "Previous",
  "common.refresh": "Refresh",
  "common.reload": "Please reload the page.",
  "common.remove": "Remove",
  "common.reset": "Reset",
  "common.retry": "Retry",
  "common.share": "Share",
  "common.unexpectedError": "Unexpected Error: {0}",
  "common.update": "Update",

  "admin.title": "Admin",
  "admin.serverInfo.title": "Server Info",
  "admin.serverInfo.description": "The Hub ID is used to identify your Cryptomator Hub instance for your license. For support, always state the displayed Hub and Keycloak version.",
  "admin.serverInfo.hubId.title": "Hub ID",
  "admin.serverInfo.hubVersion.title": "Hub Version",
  "admin.serverInfo.hubVersion.description.upToDate": "Hub is up-to-date.",
  "admin.serverInfo.hubVersion.description.updateExists": "Update to version {0} possible.",
  "admin.serverInfo.hubVersion.description.fetchingUpdatesFailed": "Unable to check for updates. Please check manually.",
  "admin.serverInfo.keycloakVersion.title": "Keycloak Version",
  "admin.serverInfo.keycloakVersion.description": "Manage Keycloak",
  "admin.licenseInfo.title": "License Info",
  "admin.licenseInfo.description": "Verify your license information. If you'd like to make changes or check your subscription status, press \"Manage Subscription\".",
  "admin.licenseInfo.email.title": "Licensed To",
  "admin.licenseInfo.seats.title": "Number of Seats",
  "admin.licenseInfo.seats.description.enoughSeats": "You have {0} unused seats remaining.",
  "admin.licenseInfo.seats.description.zeroSeats": "You have no seats remaining. Upgrade if necessary.",
  "admin.licenseInfo.seats.description.undercutSeats": "You have used more seats than you have available. Remove users or upgrade if necessary.",
  "admin.licenseInfo.issuedAt.title": "Issued At",
  "admin.licenseInfo.expiresAt.title": "Expires At",
  "admin.licenseInfo.expiresAt.description.valid": "Your license is valid.",
  "admin.licenseInfo.expiresAt.description.expired": "Your license has expired.",
  "admin.licenseInfo.manageSubscription": "Manage Subscription",
  "admin.licenseInfo.type.title": "Type",
  "admin.licenseInfo.getLicense": "Get License",
  "admin.licenseInfo.selfHostedNoLicense.description": "Thank you for using Cryptomator Hub! You have been granted the Community License. If you need more seats, upgrade your license.",
  "admin.licenseInfo.managedNoLicense.description": "Thank you for using Cryptomator Hub! You currently have no active license.",

  "archiveVaultDialog.title": "Archive Vault",
  "archiveVaultDialog.description": "Archiving a vault makes it inactive. This may free up occupied seats. An archived vault can be reactivated later.",
  "archiveVaultDialog.confirm": "Archive",

  "auditLog.title": "Audit Logs",
  "auditLog.order.ascending": "Ascending",
  "auditLog.order.descending": "Descending",
  "auditLog.filter": "Filter",
  "auditLog.filter.startDate": "Start Date",
  "auditLog.filter.endDate": "End Date",
  "auditLog.timestamp": "Timestamp",
  "auditLog.type": "Event",
  "auditLog.details": "Details",
  "auditLog.details.device.register": "Register Device",
  "auditLog.details.device.remove": "Remove Device",
  "auditLog.details.vault.create": "Create Vault",
  "auditLog.details.vault.update": "Update Vault",
  "auditLog.details.vaultAccess.grant": "Grant Vault Access",
  "auditLog.details.vaultKey.retrieve": "Retrieve Vault Key",
  "auditLog.details.vaultMember.add": "Add Vault Member",
  "auditLog.details.vaultMember.remove": "Remove Vault Member",
  "auditLog.pagination.showing": "Showing entries {0} to {1}",
  "auditLog.paymentRequired.message": "License Required",
  "auditLog.paymentRequired.description": "Audit Logs are only available with a paid license. You can get one in the admin section.",
  "auditLog.paymentRequired.openAdminSection": "Open Admin Section",

  "authenticateVaultAdminDialog.title": "Manage Vault",
  "authenticateVaultAdminDialog.description": "Type in the vault admin password to manage it.",
  "authenticateVaultAdminDialog.password": "Vault Admin Password",
  "authenticateVaultAdminDialog.error.formValidationFailed": "Password must not be empty",
  "authenticateVaultAdminDialog.error.wrongPassword": "Wrong password",

  "fetchError.title": "Fetching data failed",

  "createVault.enterRecoveryKey.title": "Recover Vault",
  "createVault.enterRecoveryKey.description": "Enter the recovery key of your existing vault to create a new vault template from it.",
  "createVault.enterRecoveryKey.recoveryKey": "Recovery Key of Your Vault",
  "createVault.enterRecoveryKey.submit": "Recover Vault",
  "createVault.enterVaultDetails.title": "Create Vault",
  "createVault.enterVaultDetails.description": "The vault admin password should be kept secret and is needed for administrative purposes. Please make sure that you remember the password.",
  "createVault.enterVaultDetails.vaultName": "Vault Name",
  "createVault.enterVaultDetails.vaultDescription": "Description",
  "createVault.enterVaultDetails.password": "Vault Admin Password",
  "createVault.enterVaultDetails.password.description": "Must contain at least 8 characters.",
  "createVault.enterVaultDetails.passwordConfirmation": "Confirm Vault Admin Password",
  "createVault.enterVaultDetails.passwordConfirmation.passwordsMatch": "Passwords match!",
  "createVault.enterVaultDetails.passwordConfirmation.passwordsDoNotMatch": "Passwords do not match",
  "createVault.showRecoveryKey.title": "Recovery Key",
  "createVault.showRecoveryKey.description": "The following recovery key can be used to restore access to the vault.",
  "createVault.showRecoveryKey.recoveryKey": "Recovery Key of Your Vault",
  "createVault.showRecoveryKey.confirmRecoveryKey": "I understand that I will lose access to the vault if I forget the vault admin password and don't have the recovery key.",
  "createVault.showRecoveryKey.submit": "Create Vault",
  "createVault.error.formValidationFailed": "Please check the form and try again.",
  "createVault.error.invalidRecoveryKey": "Recovery key is invalid.",
  "createVault.error.vaultAlreadyExists": "A vault with the given name already exists.",
  "createVault.error.downloadTemplateFailed": "Download of vault template failed: {0}",
  "createVault.error.paymentRequired": "Your Cryptomator Hub license has exceeded the number of available seats or has expired. Please inform a Hub administrator to upgrade or renew the license.",
  "createVault.success.title": "Vault created",
  "createVault.success.description": "After downloading the zipped vault folder, unpack it to any location shared with your team members.",
  "createVault.success.download": "Download zipped vault folder",
  "createVault.success.return": "Return to vault list",

  "deviceList.empty.message": "No devices",
  "deviceList.empty.description": "To add a device, add a vault from this Hub to the Cryptomator app of the device and unlock it.",
  "deviceList.title": "Devices",
  "deviceList.deviceName": "Device Name",
  "deviceList.type": "Type",
  "deviceList.added": "Added",

  "downloadVaultTemplateDialog.title": "Download Vault Template",
  "downloadVaultTemplateDialog.description": "Download and unpack the zipped vault template to any location shared with your team members. This is required only once during the initial setup.",

  "editVaultMetadataDialog.title": "Edit Vault Metadata",
  "editVaultMetadataDialog.description": "Update the name and description of the vault.",
  "editVaultMetadataDialog.vaultName": "Vault Name",
  "editVaultMetadataDialog.vaultDescription": "Description",
  "editVaultMetadataDialog.error.formValidationFailed": "Vault name must not be empty.",
  "editVaultMetadataDialog.error.vaultAlreadyExists": "A vault with the given name already exists.",

  "grantPermissionDialog.title": "Grant Permission",
  "grantPermissionDialog.description": "Grant additional devices permission to access the vault.",
  "grantPermissionDialog.submit": "Grant Permission to {0} Device(s)",

  "nav.vaults": "Vaults",
  "nav.profile.signedInAs": "Signed in as",
  "nav.profile.profile": "Your Profile",
  "nav.profile.admin": "Admin",
  "nav.profile.auditlog": "Audit Logs",
  "nav.profile.signOut": "Sign out",
  "nav.mobileMenu": "Open main menu",

  "reactivateVaultDialog.title": "Reactivate Vault",
  "reactivateVaultDialog.description": "Warning: Reactivating a vault may affect the number of available seats. If the number of available seats is exceeded, unlocking of all vaults will be blocked.",
  "reactivateVaultDialog.confirm": "Reactivate",

  "recoveryKeyDialog.title": "Recovery Key",
  "recoveryKeyDialog.description": "This is your recovery key for \"{0}\". Keep it safe, it is your only chance to regain access to a vault in case of system outage.",
  "recoveryKeyDialog.recoveryKey": "Recovery Key of Your Vault",

  "userProfile.title": "Profile",
  "userProfile.actions.manageAccount": "Manage Account",
  "userProfile.actions.changeLanguage": "Change Language",

  "vaultDetails.manageVault": "Manage Vault",
  "vaultDetails.warning.archived": "This vault is archived and cannot be unlocked.",
  "vaultDetails.description.header": "Description",
  "vaultDetails.description.empty": "No description provided.",
  "vaultDetails.information.header": "Information",
  "vaultDetails.information.created": "Created",
  "vaultDetails.sharedWith.title": "Shared with",
  "vaultDetails.actions.title": "Actions",
  "vaultDetails.actions.updatePermissions": "Update Permissions",
  "vaultDetails.actions.updatePermissions.reload": "Reload",
  "vaultDetails.actions.editVaultMetadata": "Edit Vault Metadata",
  "vaultDetails.actions.downloadVaultTemplate": "Download Vault Template",
  "vaultDetails.actions.showRecoveryKey": "Show Recovery Key",
  "vaultDetails.actions.archiveVault": "Archive Vault",
  "vaultDetails.actions.reactivateVault": "Reactivate Vault",
  "vaultDetails.error.paymentRequired": "Your Cryptomator Hub license has exceeded the number of available seats or has expired. Please inform a Hub administrator to upgrade or renew the license.",

  "vaultList.title": "Vaults",
  "vaultList.empty.title": "No vaults",
  "vaultList.empty.description": "Get started by creating a new vault.",
  "vaultList.addVault": "Add",
  "vaultList.addVault.create": "Create New",
  "vaultList.addVault.recover": "Recover Existing",
  "vaultList.filter": "Filter",
  "vaultList.filter.entry.accessibleVaults": "Accessible",
  "vaultList.filter.entry.allVaults": "All",
  "vaultList.filter.result.empty.title": "No vaults",
  "vaultList.filter.result.empty.description": "No results using that search term or filter.",
  "vaultList.search.placeholder": "Search…",
  "vaultList.badge.archived": "Archived"
}
