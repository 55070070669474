{
  "locale.en": "Englisch",
  "locale.de": "Deutsch",

  "common.add": "Hinzufügen",
  "common.apply": "Anwenden",
  "common.cancel": "Abbrechen",
  "common.close": "Schließen",
  "common.copied": "Kopiert!",
  "common.copy": "Kopieren",
  "common.download": "Herunterladen",
  "common.loading": "Laden…",
  "common.manage": "Verwalten",
  "common.next": "Weiter",
  "common.optional": "optional",
  "common.pagination": "Seitennummerierung",
  "common.previous": "Zurück",
  "common.refresh": "Aktualisieren",
  "common.reload": "Bitte lade die Seite neu.",
  "common.remove": "Entfernen",
  "common.reset": "Zurücksetzen",
  "common.retry": "Wiederholen",
  "common.share": "Teilen",
  "common.unexpectedError": "Unerwarteter Fehler: {0}",
  "common.update": "Aktualisieren",

  "admin.title": "Admin",
  "admin.serverInfo.title": "Server.Info",
  "admin.serverInfo.description": "Die Hub-ID dient zur Identifizierung deiner Cryptomator Hub-Instanz für deine Lizenz. Für den Support gib bitte immer die angezeigte Hub- und Keycloak-Version an.",
  "admin.serverInfo.hubId.title": "Hub-ID",
  "admin.serverInfo.hubVersion.title": "Hub-Version",
  "admin.serverInfo.hubVersion.description.upToDate": "Hub ist aktuell.",
  "admin.serverInfo.hubVersion.description.updateExists": "Update auf Version {0} möglich.",
  "admin.serverInfo.hubVersion.description.fetchingUpdatesFailed": "Suche nach Updates ist fehlgeschlagen. Bitte prüfe manuell.",
  "admin.serverInfo.keycloakVersion.title": "Keycloak-Version",
  "admin.serverInfo.keycloakVersion.description": "Keycloak verwalten",
  "admin.licenseInfo.title": "Lizenz-Infos",
  "admin.licenseInfo.description": "Überprüfe deine Lizenzinformationen. Wenn du Änderungen vornehmen oder deinen Abonnementstatus überprüfen möchtest, drücke „Abonnement verwalten“.",
  "admin.licenseInfo.email.title": "Lizenziert für",
  "admin.licenseInfo.seats.title": "Anzahl der Sitze",
  "admin.licenseInfo.seats.description.enoughSeats": "Du hast {0} unbenutzte Sitze übrig.",
  "admin.licenseInfo.seats.description.zeroSeats": "Du hast keine Sitze mehr frei. Upgrade, wenn nötig.",
  "admin.licenseInfo.seats.description.undercutSeats": "Du hast mehr Sitze belegt, als du zur Verfügung hast. Entferne ggf. Benutzer oder führe ein Upgrade durch.",
  "admin.licenseInfo.issuedAt.title": "Ausgestellt am",
  "admin.licenseInfo.expiresAt.title": "Läuft ab am",
  "admin.licenseInfo.expiresAt.description.valid": "Deine Lizenz ist gültig.",
  "admin.licenseInfo.expiresAt.description.expired": "Deine Lizenz ist abgelaufen.",
  "admin.licenseInfo.manageSubscription": "Abonnement verwalten",
  "admin.licenseInfo.type.title": "Typ",
  "admin.licenseInfo.getLicense": "Lizenz erhalten",
  "admin.licenseInfo.selfHostedNoLicense.description": "Vielen Dank, dass du Cryptomator Hub nutzt! Du hast die Community-Lizenz erhalten. Wenn du mehr Sitze benötigst, erweiter deine Lizenz.",
  "admin.licenseInfo.managedNoLicense.description": "Vielen Dank, dass du Cryptomator Hub nutzt! Du hast aktuell keine aktive Lizenz.",

  "archiveVaultDialog.title": "Tresor archivieren",
  "archiveVaultDialog.description": "Das Archivieren eines Tresors macht ihn inaktiv. Dadurch können belegte Sitze frei werden. Ein archivierter Tresor kann später wieder aktiviert werden.",
  "archiveVaultDialog.confirm": "Archivieren",

  "auditLog.title": "Audit-Logs",
  "auditLog.order.ascending": "Aufsteigend",
  "auditLog.order.descending": "Absteigend",
  "auditLog.filter": "Filter",
  "auditLog.filter.startDate": "Startdatum",
  "auditLog.filter.endDate": "Enddatum",
  "auditLog.timestamp": "Zeitstempel",
  "auditLog.type": "Ereignis",
  "auditLog.details": "Details",
  "auditLog.details.device.register": "Gerät registrieren",
  "auditLog.details.device.remove": "Gerät entfernen",
  "auditLog.details.vault.create": "Tresor erstellen",
  "auditLog.details.vault.update": "Tresor aktualisieren",
  "auditLog.details.vaultAccess.grant": "Tresor-Zugriff gewähren",
  "auditLog.details.vaultKey.retrieve": "Tresor-Schlüssel abrufen",
  "auditLog.details.vaultMember.add": "Tresor-Mitglied hinzufügen",
  "auditLog.details.vaultMember.remove": "Tresor-Mitglied entfernen",
  "auditLog.pagination.showing": "Zeige Einträge {0} bis {1}",
  "auditLog.paymentRequired.message": "Lizenz erforderlich",
  "auditLog.paymentRequired.description": "Audit-Logs sind nur mit einer kostenpflichtigen Lizenz verfügbar. Du kannst eine im Admin-Bereich erhalten.",
  "auditLog.paymentRequired.openAdminSection": "Admin-Bereich öffnen",

  "authenticateVaultAdminDialog.title": "Tresor verwalten",
  "authenticateVaultAdminDialog.description": "Gib das Tresor-Admin-Passwort des Tresors ein, um ihn zu verwalten.",
  "authenticateVaultAdminDialog.password": "Tresor-Admin-Passwort",
  "authenticateVaultAdminDialog.error.formValidationFailed": "Passwort darf nicht leer sein",
  "authenticateVaultAdminDialog.error.wrongPassword": "Falsches Passwort",

  "fetchError.title": "Abruf von Daten fehlgeschlagen",

  "createVault.enterRecoveryKey.title": "Tresor wiederherstellen",
  "createVault.enterRecoveryKey.description": "Gib den Wiederherstellungsschlüssel deines bestehenden Tresors ein, um daraus eine neue Tresorvorlage zu erstellen.",
  "createVault.enterRecoveryKey.recoveryKey": "Wiederherstellungsschlüssel für deinen Tresor",
  "createVault.enterRecoveryKey.submit": "Tresor wiederherstellen",
  "createVault.enterVaultDetails.title": "Tresor erstellen",
  "createVault.enterVaultDetails.description": "Das Tresor-Admin-Passwort sollte geheim gehalten werden und wird nur für administrative Zwecke benötigt. Bitte achte darauf, dass du dir das Passwort merkst.",
  "createVault.enterVaultDetails.vaultName": "Tresorname",
  "createVault.enterVaultDetails.vaultDescription": "Beschreibung",
  "createVault.enterVaultDetails.password": "Tresor-Admin-Passwort",
  "createVault.enterVaultDetails.password.description": "Muss mindestens 8 Zeichen enthalten.",
  "createVault.enterVaultDetails.passwordConfirmation": "Tresor-Admin-Passwort bestätigen",
  "createVault.enterVaultDetails.passwordConfirmation.passwordsMatch": "Passwörter stimmen überein!",
  "createVault.enterVaultDetails.passwordConfirmation.passwordsDoNotMatch": "Passwörter stimmen nicht überein",
  "createVault.showRecoveryKey.title": "Wiederherstellungsschlüssel",
  "createVault.showRecoveryKey.description": "Der folgende Wiederherstellungsschlüssel kann verwendet werden, um den Zugriff auf den Tresor wiederherzustellen.",
  "createVault.showRecoveryKey.recoveryKey": "Wiederherstellungsschlüssel für deinen Tresor",
  "createVault.showRecoveryKey.confirmRecoveryKey": "Mir ist klar, dass ich den Zugang zum Tresor verliere, wenn ich das Tresor-Admin-Passwort vergesse und den Wiederherstellungsschlüssel nicht habe.",
  "createVault.showRecoveryKey.submit": "Tresor erstellen",
  "createVault.error.formValidationFailed": "Bitte überprüfe das Formular und versuche es erneut.",
  "createVault.error.invalidRecoveryKey": "Wiederherstellungsschlüssel ist ungültig.",
  "createVault.error.vaultAlreadyExists": "Ein Tresor mit dem angegebenen Namen existiert bereits.",
  "createVault.error.downloadTemplateFailed": "Download der Tresorvorlage fehlgeschlagen: {0}",
  "createVault.error.paymentRequired": "Deine Cryptomator Hub Lizenz hat die Anzahl der verfügbaren Sitze überschritten oder ist abgelaufen. Bitte informiere einen Hub-Administrator, um die Lizenz zu erneuern oder zu erweitern.",
  "createVault.success.title": "Tresor erstellt",
  "createVault.success.description": "Nachdem du den gezippten Tresorordner heruntergeladen hast, entpacke ihn an einem beliebigen Ort, der für deine Teammitglieder freigegeben ist.",
  "createVault.success.download": "Gezippten Tresorordner herunterladen",
  "createVault.success.return": "Zurück zur Tresorliste",

  "deviceList.empty.message": "Keine Geräte",
  "deviceList.empty.description": "Um ein Gerät hinzuzufügen, füge einen Tresor aus diesem Hub zur Cryptomator-App des Geräts hinzu und entsperre es.",
  "deviceList.title": "Geräte",
  "deviceList.deviceName": "Gerätename",
  "deviceList.type": "Typ",
  "deviceList.added": "Hinzugefügt",

  "downloadVaultTemplateDialog.title": "Tresorvorlage herunterladen",
  "downloadVaultTemplateDialog.description": "Lade die gezippte Tresorvorlage herunter und entpacke ihn an einem beliebigen Ort, der für deine Teammitglieder freigegeben ist. Dies ist einmal für die Ersteinrichtung erforderlich.",

  "editVaultMetadataDialog.title": "Tresor-Metadaten bearbeiten",
  "editVaultMetadataDialog.description": "Aktualisiere den Namen und die Beschreibung des Tresors.",
  "editVaultMetadataDialog.vaultName": "Tresorname",
  "editVaultMetadataDialog.vaultDescription": "Beschreibung",
  "editVaultMetadataDialog.error.formValidationFailed": "Tresorname darf nicht leer sein.",
  "editVaultMetadataDialog.error.conflict": "Ein Tresor mit dem angegebenen Namen existiert bereits.",

  "grantPermissionDialog.title": "Berechtigung erteilen",
  "grantPermissionDialog.description": "Berechtige weitere Geräten auf den Tresor zuzugreifen.",
  "grantPermissionDialog.submit": "Berechtigung für {0} Gerät(e) gewähren",

  "nav.vaults": "Tresore",
  "nav.profile.signedInAs": "Eingeloggt als",
  "nav.profile.profile": "Dein Profil",
  "nav.profile.admin": "Admin",
  "nav.profile.auditlog": "Audit-Logs",
  "nav.profile.signOut": "Abmelden",
  "nav.mobileMenu": "Hauptmenü öffnen",

  "reactivateVaultDialog.title": "Tresor reaktivieren",
  "reactivateVaultDialog.description": "Warnung: Die Reaktivierung eines Tresors kann die Anzahl der verfügbaren Sitze beeinflussen. Wenn die Anzahl der verfügbaren Sitze überschritten wird, wird das Entsperren aller Tresore blockiert.",
  "reactivateVaultDialog.confirm": "Reaktivieren",

  "recoveryKeyDialog.title": "Wiederherstellungsschlüssel",
  "recoveryKeyDialog.description": "Dies ist dein Wiederherstellungsschlüssel für „{0}“. Verwahre ihn gut, er ist im Falle eines Systemausfalls deine einzige Möglichkeit, auf einen Tresor zuzugreifen.",
  "recoveryKeyDialog.recoveryKey": "Wiederherstellungsschlüssel für deinen Tresor",

  "userProfile.title": "Profil",
  "userProfile.actions.manageAccount": "Account verwalten",
  "userProfile.actions.changeLanguage": "Sprache ändern",

  "vaultDetails.manageVault": "Tresor verwalten",
  "vaultDetails.description.header": "Beschreibung",
  "vaultDetails.description.empty": "Keine Beschreibung vorhanden.",
  "vaultDetails.information.header": "Information",
  "vaultDetails.information.created": "Erstellt",
  "vaultDetails.sharedWith.title": "Geteilt mit",
  "vaultDetails.actions.title": "Aktionen",
  "vaultDetails.actions.updatePermissions": "Berechtigungen aktualisieren",
  "vaultDetails.actions.updatePermissions.reload": "Neu laden",
  "vaultDetails.actions.editVaultMetadata": "Tresor-Metadaten bearbeiten",
  "vaultDetails.actions.downloadVaultTemplate": "Tresor-Vorlage herunterladen",
  "vaultDetails.actions.showRecoveryKey": "Wiederherstellungsschlüssel anzeigen",
  "vaultDetails.actions.archiveVault": "Tresor archivieren",
  "vaultDetails.actions.reactivateVault": "Tresor reaktivieren",
  "vaultDetails.error.paymentRequired": "Deine Cryptomator Hub Lizenz hat die Anzahl der verfügbaren Sitze überschritten oder ist abgelaufen. Bitte informiere einen Hub-Administrator, um die Lizenz zu erneuern oder zu erweitern.",

  "vaultList.title": "Tresore",
  "vaultList.empty.title": "Keine Tresore",
  "vaultList.empty.description": "Beginne mit der Erstellung eines neuen Tresors.",
  "vaultList.addVault": "Hinzufügen",
  "vaultList.addVault.create": "Neu erstellen",
  "vaultList.addVault.recover": "Wiederherstellen",
  "vaultList.filter": "Filter",
  "vaultList.filter.entry.accessibleVaults": "Zugreifbar",
  "vaultList.filter.entry.allVaults": "Alle",
  "vaultList.filter.result.empty.title": "Keine Tresore",
  "vaultList.filter.result.empty.description.": "Keinen Treffer mit diesem Suchbegriff oder Filter.",
  "vaultList.search.placeholder": "Suche…",
  "vaultList.badge.archived": "Archiviert"
}
